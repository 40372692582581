@use '@angular/material' as mat;
@include mat.core();

// Overlay
@import '@angular/cdk/overlay-prebuilt.css';

// General utilities
@import 'utils';

// Material headline-5
$flyguys-h1: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(32),
);

// Material headline-6
$flyguys-h2: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(28),
);

// Material subtitle-1
$flyguys-h3: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(26),
);

// Material subtitle-2
$flyguys-h4: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(24),
);

$flyguys-h5: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(20),
);

$flyguys-h6: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 600,
  $font-size: em(18),
);

$flyguys-body-1: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(16),
);

$flyguys-body-2: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(14),
);

$flyguys-caption: mat.define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: em(12),
);

$flyguys-typography-config: mat.define-typography-config(
  $headline-5: $flyguys-h1,
  $headline-6: $flyguys-h2,
  $subtitle-1: $flyguys-h3,
  $subtitle-2: $flyguys-h4,
  $body-1: $flyguys-body-1,
  $body-2: $flyguys-body-2,
  $caption: $flyguys-caption,
);

$flyguys-primary-palette: (
  50: #f7ebe6,
  100: #ebcec0,
  200: #dead97,
  300: #d08c6d,
  400: #c6734d,
  500: #bc5a2e,
  600: #b65229,
  700: #ad4823,
  800: #a53f1d,
  900: #972e12,
  A100: #ffd2c9,
  A200: #ffa896,
  A400: #ff7e63,
  A700: #ff6949,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-accent-palette: (
  50: #e6e8e7,
  100: #c2c5c4,
  200: #999f9d,
  300: #707875,
  400: #515b58,
  500: #323e3a,
  600: #2d3834,
  700: #26302c,
  800: #1f2825,
  900: #131b18,
  A100: #61ffca,
  A200: #2effb9,
  A400: #00faa7,
  A700: #00e096,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-warn-palette: (
  50: #fae6e6,
  100: #f2c1c1,
  200: #e99797,
  300: #e06d6d,
  400: #da4e4e,
  500: #d32f2f,
  600: #ce2a2a,
  700: #c82323,
  800: #c21d1d,
  900: #b71212,
  A100: #ffe7e7,
  A200: #ffb4b4,
  A400: #ff8181,
  A700: #ff6868,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$flyguys-primary: mat.define-palette($flyguys-primary-palette, 500);

$flyguys-accent: mat.define-palette($flyguys-accent-palette, 500);

$flyguys-warn: mat.define-palette($flyguys-warn-palette, 500);

$flyguys-theme: mat.define-light-theme(
  (
    color: (
      primary: $flyguys-primary,
      accent: $flyguys-accent,
      warn: $flyguys-warn,
    ),
    typography: $flyguys-typography-config,
  )
);

@include mat.typography-hierarchy($flyguys-typography-config);
@include mat.all-component-themes($flyguys-theme);

// AG Grid
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

// Tagus SCSS
@import './globals/tagus';

// Variables
@import './variables';

// Overrides
@import './overrides';

// General Global Elements
@import './globals/elements';

// Utilities SCSS
@import './globals/utilities';

// UI Kit SCSS
@import './globals/ui-kit';

// Form SCSS
@import './globals/form';

// Sidemenu SCSS
@import './globals/sidebar';

@import './globals/buttons';

@import './globals/menu';

@import './components/action-framework';

// Reset from Tagus/Material
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  background-color: #f5f5f5;
}

a {
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    text-decoration: none;
    color: var(--mainColor);
  }
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font: {
    family: var(--fontFamily) !important;
    size: var(--fontSize) !important;
  }
}

// @TODO Remove this code and implement the proper common styling in the tables.scss file
.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: var(--text-primary, rgba(0, 0, 0, 0.87)) !important;
  font-feature-settings:
    'clig' off,
    'liga' off;
  text-transform: none;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
  letter-spacing: 0.17px;
}

// @TODO After fixing the issue listed above, move this file to the top
@import './globals/tables';

.breadcrumb {
  border: none;
  font-size: 16px;
  font-weight: 500;

  a {
    color: #00000099 !important;
  }

  .breadcrumb-item {
    &::before {
      color: inherit;
    }
  }

  .breadcrumb-last {
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: 600;
    color: black;

    a {
      color: black !important;
    }

    &::before {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.mdc-tab {
  font-family: 'Open Sans', 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.material-symbols-rounded {
  color: #707070;
  font-weight: 200;
  font-size: 20px;
}

.white-snackbar > .mdc-snackbar__surface {
  background-color: var(--mainColor) !important;
}

.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-backdrop,
.cdk-overlay-container {
  z-index: 10000;
  overflow: auto;
}

.message-snackbar > .mdc-snackbar__surface {
  background-color: var(--mainColor) !important;
}

// Action Framework Popup
// This is outside of the style encapsulation, hence why is here
lib-filter-dialog {
  .filter-popup {
    padding: 10px !important;

    .filter-action-buttons {
      padding-top: 10px !important;
      border-top: 1px solid #d7d7d7;
    }

    .filter-options-container {
      p {
        font-size: 15px !important;
        line-height: 1 !important;
        padding: 5px 10px;
        margin: 0;

        &:hover {
          background-color: #d7d7d7;
        }
      }
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    .radios {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #d7d7d7;
      padding-bottom: 15px;
    }
  }
}

.total-gray {
  color: gray !important;
  font-weight: 500 !important;
}

.role-list-permission {
  width: 120px;
  text-transform: capitalize;
}

.tree-icon {
  margin-left: 5px;
  margin-right: 1px;
  opacity: 0.5;
}

.not-allowed {
  cursor: not-allowed !important;
}

.mat-divider {
  border-top-color: #e0e0e0 !important;
}

// thumbnail styles.
.video img {
  width: 100%;
  position: relative;
}

.video {
  position: relative;
}

.play-button-outer {
  width: 50px;
  height: 50px;
  background-color: #a09f9f91;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-button {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 12px 17px;
  border-color: transparent transparent transparent #fff;
  opacity: 0.75;
}

.play-button-outer:hover .play-button {
  opacity: 1;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 330px;
  min-width: unset;
  max-width: unset;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header {
  background-color: var(--mainColor);
  border-radius: 5px 5px 0 0;
  padding: 15px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header h5 {
  color: var(--whiteColor);
  font-size: 18px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header button {
  color: var(--whiteColor);
  cursor: pointer;
  font-size: 14px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li {
  border-bottom: 1px solid #eeeeee;
  transition: var(--transition);
  padding: 15px 35px 15px 15px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li img {
  width: 50px;
  border-radius: 50%;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title {
  margin-left: 14px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title h6 {
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 600;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span {
  font-size: 13px;
  position: relative;
  padding-left: 18px;
  color: var(--paragraphColor);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span i {
  left: 0;
  top: 50%;
  line-height: 1;
  margin-top: 0.7px;
  position: absolute;
  transform: translateY(-50%);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .link-btn {
  inset: 0;
  z-index: 1;
  display: block;
  position: absolute;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .close-btn {
  transition: var(--transition);
  color: var(--paragraphColor);
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  right: 15px;
  z-index: 2;
  top: 50%;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .close-btn:hover {
  color: red;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .icon {
  position: relative;
  font-size: 30px;
  top: 2px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li:hover {
  background-color: #f8f8f8;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li:last-child {
  border-bottom: none;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item {
  width: 25%;
  flex: 0 0 auto;
  display: block;
  text-align: center;
  color: var(--paragraphColor);
  padding-top: 8px;
  padding-bottom: 8px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item span {
  margin-top: 4px;
  font-size: 14px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item:hover {
  color: var(--mainColor);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .d-flex.flex-wrap {
  padding: 7px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer {
  border-top: 1px solid #eeeeee;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 16px;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a {
  font-size: 14px;
  padding-right: 18px;
  color: var(--mainColor);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a i {
  top: 50%;
  right: 0;
  line-height: 1;
  margin-top: 1px;
  position: absolute;
  transform: translateY(-50%);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a:hover {
  color: var(--blackColor);
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
}

.border-none {
  border: none !important;
}

.pin-notification {
  font-size: 1.2em;
  color: #aac59fbd;
}

.message-style {
  margin-left: 15px;
}

.div-container {
  width: 100%;
}

.div-icon {
  text-align: center;
}

.manager-folder-icon {
  font-size: em(61);
}

.deliverable-files-content .mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.file-tree-container {
  border: solid 1px #dfdfdf;
  padding: 7px;
  border-radius: 12px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.27);
}

.main-color {
  color: var(--mainColor) !important;
}

.main-bg {
  color: var(--mainColor) !important;
}

.primary-color {
  color: var(--primaryColor);
}

.secondary-color {
  color: var(--secondaryColor);
}

// Use this class to reduce the height of form-fields/inputs
.compressed-form-field-density {
  @include mat.form-field-density(minimum);
}

.compressed-checkbox {
  @include mat.checkbox-density(minimum);
}

.preserve-linebreaks {
  white-space: pre-wrap;
}

// Better line height for ABP confirmation modals
abp-confirmation .confirmation {
  line-height: 1.3;
}

// Fixes Uppy's close button being outside of the drop area
.uppy-Dashboard-close {
  right: 10px !important;
  top: 3px !important;
  color: black !important;
  font-size: 30px !important;
}

// Fix for a dropdown styling inside mat-autocomplete
.site-autocomplete .mdc-list-item__primary-text {
  width: 100%;
}

// Make material use all of the width in a option
.mdc-list-item__primary-text {
  width: 100%;
}

// Used in several contact search autocomplete through many components
.contact-info-container {
  display: flex;
  flex-direction: column;
}

.contact-details {
  display: flex;
  justify-content: space-between;
}

.location-name {
  font-weight: bold;
  display: block;
}

.location-address {
  font-size: 0.85rem;
  color: #666;
}

.h-56px {
  height: 56px;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

// Allows instance of video to use its parent weight instead of
// the full video frame
.azuremediaplayer {
  height: 100%;
  width: 100%;
}

.badge-list .grid-badge {
  margin-right: 8px;
  margin-bottom: 8px;
}

// Use this class when an element contains output from flg-rich-text-input
.flg-editor-output {
  white-space: pre-wrap;
  line-height: 1.3;

  a {
    color: var(--linkColor) !important;
    text-decoration: underline;
  }

  h1 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  p {
    font-size: 15px;
  }

  li {
    p {
      margin: 0 !important;
    }
  }
}

// Gives Material Icons a common coloring
.material-symbols-outlined {
  color: var(--materialIconsColor);
}

.custom-tooltip {
  background-color: #616161;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  max-width: 250px;
  word-wrap: break-word;
  position: absolute;
  z-index: 1000;
  line-height: 1.5;
  overflow: hidden !important;

  a {
    color: #2196f3 !important;
    text-decoration: underline;
  }

  a:hover {
    color: #64b5f6 !important;
  }

  b,
  strong {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c1bfbf;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #b1b0b0;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c1bfbf #f1f1f1;
}

// Common container used in cards
// Consumed by pilot profile
.card-container {
  border: 1px solid var(--appBorderColor);
  border-radius: 8px;
  padding: 1rem;

  .card-body {
    padding: 0 !important;
  }

  h3 {
    font-size: 16px;
    color: var(--primaryColor);
    margin: 0;
  }
}

// Line Height 1.5 to prevent clipping text when using overflows
.lh-15 {
  line-height: 1.5;
}
