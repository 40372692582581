// Border Utilities
.border-top-radius {
  border-radius: 10px 10px 0 0 !important;
}

.border-bottom-radius {
  border-radius: 0 0 10px 10px !important;
}

.border-radius {
  border-radius: 10px !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.text-white {
  color: #ffffff !important;
}

// Spacing Utilities
// These do not map to Bootstrap
// Tagus Spacing classes are prefixed with `t`
.t-p-0 {
  padding: 0 !important;
}
.t-ps-0 {
  padding-left: 0 !important;
}
.t-pe-0 {
  padding-right: 0 !important;
}
.t-pt-0 {
  padding-top: 0 !important;
}
.t-pb-0 {
  padding-bottom: 0 !important;
}
.t-p-5 {
  padding: 10px !important;
}
.t-p-10 {
  padding: 10px !important;
}
.t-p-12 {
  padding: 10px !important;
}
.t-p-15 {
  padding: 15px !important;
}
.t-p-20 {
  padding: 20px !important;
}
.t-p-25 {
  padding: 25px !important;
}
.t-p-30 {
  padding: 30px !important;
}
.t-mb-10 {
  margin-bottom: 10px !important;
}
.t-pt-10 {
  padding-top: 10px !important;
}
.t-pt-15 {
  padding-top: 15px !important;
}
.t-mt-5 {
  margin-top: 5px !important;
}
.t-mb-3 {
  margin-bottom: 3px !important;
}
.t-mb-4 {
  margin-bottom: 4px !important;
}
.t-mb-5 {
  margin-bottom: 5px !important;
}
.t-pt-5 {
  padding-top: 20px !important;
}
.t-pt-20 {
  padding-top: 20px !important;
}
.t-pt-25 {
  padding-top: 25px !important;
}
.t-pt-30 {
  padding-top: 30px !important;
}
.t-pt-50 {
  padding-top: 50px !important;
}
.t-pb-15 {
  padding-bottom: 15px !important;
}
.t-pb-5 {
  padding-bottom: 5px !important;
}
.t-pb-10 {
  padding-bottom: 10px !important;
}
.t-pb-20 {
  padding-bottom: 20px !important;
}
.t-pb-25 {
  padding-bottom: 25px !important;
}
.t-pb-50 {
  padding-bottom: 50px !important;
}
.t-pb-30 {
  padding-bottom: 30px !important;
}
.t-ps-5 {
  padding-left: 5px !important;
}
.t-ps-10 {
  padding-left: 10px !important;
}
.t-ps-15 {
  padding-left: 15px !important;
}
.t-ps-20 {
  padding-left: 20px !important;
}
.t-ps-25 {
  padding-left: 25px !important;
}
.t-ps-30 {
  padding-left: 30px !important;
}
.t-pe-5 {
  padding-right: 5px !important;
}
.t-pe-10 {
  padding-right: 10px !important;
}
.t-pe-15 {
  padding-right: 15px !important;
}
.t-pe-20 {
  padding-right: 20px !important;
}
.t-pe-25 {
  padding-right: 25px !important;
}
.t-pe-30 {
  padding-right: 30px !important;
}
.t-ms-0 {
  margin-left: 0 !important;
}
.t-ms-5 {
  margin-left: 5px !important;
}
.t-me-0 {
  margin-right: 0 !important;
}
.t-mt-0 {
  margin-top: 0 !important;
}
.t-mb-0 {
  margin-bottom: 0 !important;
}
.t-mt-8 {
  margin-top: 8px !important;
}
.t-mt-12 {
  margin-top: 12px !important;
}
.t-mb-12 {
  margin-bottom: 12px !important;
}
.t-mb-8 {
  margin-bottom: 8px !important;
}
.t-mt-10 {
  margin-top: 10px !important;
}
.t-m-15 {
  margin: 15px !important;
}
.t-m-20 {
  margin: 20px !important;
}
.t-m-25 {
  margin: 25px !important;
}
.t-m-30 {
  margin: 30px !important;
}
.t-mt-15 {
  margin-top: 15px !important;
}
.t-mt-20 {
  margin-top: 20px !important;
}
.t-mt-25 {
  margin-top: 25px !important;
}
.t-mt-30 {
  margin-top: 30px !important;
}
.t-mt-18 {
  margin-top: 18px !important;
}
.t-mb-18 {
  margin-bottom: 18px !important;
}
.t-mb-15 {
  margin-bottom: 15px !important;
}
.t-mb-20 {
  margin-bottom: 20px !important;
}
.t-mb-25 {
  margin-bottom: 25px !important;
}
.t-mb-30 {
  margin-bottom: 30px !important;
}
.t-ms-15 {
  margin-left: 15px !important;
}
.t-ms-20 {
  margin-left: 20px !important;
}
.t-ms-25 {
  margin-left: 25px !important;
}
.t-ms-30 {
  margin-left: 30px !important;
}
.t-pe-10 {
  padding-right: 10px !important;
}
.t-pe-12 {
  padding-right: 12px !important;
}
.t-ps-12 {
  padding-left: 12px !important;
}
.t-pe-12 {
  padding-right: 12px !important;
}
.t-pb-12 {
  padding-bottom: 12px !important;
}
.t-pt-12 {
  padding-top: 12px !important;
}
.t-me-5 {
  margin-right: 5px !important;
}
.t-me-10 {
  margin-right: 10px !important;
}
.t-ms-10 {
  margin-left: 10px !important;
}
.t-me-12 {
  margin-right: 12px !important;
}
.t-ms-12 {
  margin-left: 12px !important;
}
.t-me-15 {
  margin-right: 15px !important;
}
.t-me-20 {
  margin-right: 20px !important;
}
.t-me-25 {
  margin-right: 25px !important;
}
.t-me-30 {
  margin-right: 30px !important;
}
.t-ml-8 {
  margin-left: 8px !important;
}

.t-w-35 {
  width: 35px !important;
}
.t-h-35 {
  height: 35px !important;
}
.t-w-85 {
  width: 85px !important;
}
.t-h-85 {
  height: 85px !important;
}
.t-w-75 {
  width: 75px !important;
}
.t-h-75 {
  height: 75px !important;
}
.t-w-65 {
  width: 65px !important;
}
.t-h-65 {
  height: 65px !important;
}
.t-w-55 {
  width: 55px !important;
}
.t-h-55 {
  height: 55px !important;
}
.t-w-45 {
  width: 45px !important;
}
.t-h-45 {
  height: 45px !important;
}
.t-h-auto {
  height: auto !important;
}
.t-h-100vh {
  height: 100vh !important;
}

// Font Utilities
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}

.lh-35 {
  line-height: 35px !important;
}
.lh-38 {
  line-height: 38px !important;
}
.lh-1 {
  line-height: 1 !important;
}

.small,
small {
  font-size: 70% !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}
.fs-15 {
  font-size: 15px;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
}

// Box Shadow used by Tagus
.box-shadow {
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07) !important;
}

// Color Utilities
.bg-gray {
  background-color: #f7f8f7 !important;
}
.main-color {
  color: var(--mainColor) !important;
}
.bg-light-gray {
  background-color: #f7faff !important;
}
.gray-color {
  color: #096285 !important;
}
.muted-color {
  color: #a9a9c8 !important;
}

.text-muted {
  color: rgba(33, 37, 41, 0.75) !important;
}

.main-hover-color {
  &:hover {
    color: var(--mainColor) !important;
  }
}

.bg-main-hover-color {
  &:hover {
    color: var(--whiteColor) !important;
    background-color: var(--mainColor) !important;
  }
}

// Misc
.list-unstyled {
  list-style-type: none;
}

.cursor-pointer {
  cursor: pointer;
}

// Dark Mode CSS
.dark-theme {
  .bg-white {
    background-color: #14171c !important;
  }
  .box-shadow {
    box-shadow: unset !important;
  }
  .bg-gray {
    background-color: #0f100e !important;
  }
  .gray-color {
    color: #828690 !important;
  }
  .muted-color {
    color: #6a6a73 !important;
  }
  .bg-light-gray {
    background-color: #232830 !important;
  }
}

// RTL CSS
.rtl-enabled {
  .ms-auto {
    margin-right: auto !important;
  }
  .me-auto {
    margin-left: auto !important;
  }
  .text-start {
    text-align: right !important;
  }
  .text-end {
    text-align: left !important;
  }
  .border-top-radius {
    border-radius: 10px 10px 0 0 !important;
  }
  .border-bottom-radius {
    border-radius: 0 0 10px 10px !important;
  }
  .ps-0 {
    padding-right: 0 !important;
  }
  .pe-0 {
    padding-left: 0 !important;
  }
  .ps-5 {
    padding-right: 5px !important;
  }
  .ps-10 {
    padding-right: 10px !important;
  }
  .ps-15 {
    padding-right: 15px !important;
  }
  .ps-20 {
    padding-right: 20px !important;
  }
  .ps-25 {
    padding-right: 25px !important;
  }
  .ps-30 {
    padding-right: 30px !important;
  }
  .pe-5 {
    padding-left: 5px !important;
  }
  .pe-10 {
    padding-left: 10px !important;
  }
  .pe-15 {
    padding-left: 15px !important;
  }
  .pe-20 {
    padding-left: 20px !important;
  }
  .pe-25 {
    padding-left: 25px !important;
  }
  .pe-30 {
    padding-left: 30px !important;
  }
  .ms-0 {
    margin-right: 0 !important;
  }
  .ms-5 {
    margin-right: 5px !important;
  }
  .me-0 {
    margin-left: 0 !important;
  }
  .ms-15 {
    margin-right: 15px !important;
  }
  .ms-20 {
    margin-right: 20px !important;
  }
  .ms-25 {
    margin-right: 25px !important;
  }
  .ms-30 {
    margin-right: 30px !important;
  }
  .pe-10 {
    padding-left: 10px !important;
  }
  .pe-12 {
    padding-left: 12px !important;
  }
  .ps-12 {
    padding-right: 12px !important;
  }
  .pe-12 {
    padding-left: 12px !important;
  }
  .me-5 {
    margin-left: 5px !important;
  }
  .me-10 {
    margin-left: 10px !important;
  }
  .ms-10 {
    margin-right: 10px !important;
  }
  .me-12 {
    margin-left: 12px !important;
  }
  .ms-12 {
    margin-right: 12px !important;
  }
  .me-15 {
    margin-left: 15px !important;
  }
  .me-20 {
    margin-left: 20px !important;
  }
  .me-25 {
    margin-left: 25px !important;
  }
  .me-30 {
    margin-left: 30px !important;
  }
}
