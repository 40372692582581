$af-gray: #b7b7b7;
$af-subtitle: rgba(0, 0, 0, 0.6);

app-action-framework {
  .p-datatable-wrapper {
    max-height: unset !important;

    .p-frozen-column {
      position: unset !important;
    }

    lib-default-cell {
      white-space: normal;
    }
  }

  .p-datatable-table.p-datatable-scrollable-table.ng-star-inserted {
    width: 100% !important;
  }
}

.lib-action-form {
  a {
    color: white !important;
  }
}

.mat-mdc-snack-bar-container {
  $success-text-color: #1e4620;
  $success-bg-color: #edf7ed;
  $success-icon-color: #2e7d32;
  $error-text-color: #5f2120;
  $error-bg-color: #fdeded;
  $error-icon-color: #d32f2f;
  --mdc-snackbar-supporting-text-weight: 600;

  .mdc-snackbar__label {
    padding-left: 40px !important;
  }

  &.greenNoMatch {
    --mdc-snackbar-container-color: #{$success-bg-color};
    --mdc-snackbar-supporting-text-color: #{$success-text-color};
    position: relative;
    padding-left: 45px !important;

    .mat-mdc-snack-bar-action {
      background-color: #{$success-text-color} !important;
      color: #{$success-bg-color} !important;
      padding: 5px 10px !important;
      font-size: 10px !important;
      height: 25px;
    }
  }

  &.greenNoMatch::before {
    font-family: 'Material Symbols Outlined';
    color: #{$success-icon-color} !important;
    content: '\e86c';
    font-size: 22px;
    left: 30px;
    position: relative;
    z-index: 1;
  }

  &.red-snackbar,
  &.redNoMatch {
    --mdc-snackbar-container-color: #{$error-bg-color};
    --mdc-snackbar-supporting-text-color: #{$error-text-color};
    position: relative;
    padding-left: 45px !important;

    .mat-mdc-snack-bar-action {
      background-color: #{$error-text-color} !important;
      color: #{$error-bg-color} !important;
      padding: 5px 10px !important;
      font-size: 10px !important;
      height: 25px;
    }
  }

  &.red-snackbar::before,
  &.redNoMatch::before {
    font-family: 'Material Symbols Outlined';
    color: #{$error-icon-color} !important;
    content: '\e000';
    font-size: 22px;
    left: 30px;
    position: relative;
    z-index: 1;
  }
}

.custom-modalbox {
  .action-filters {
    border-bottom: 1px $af-gray solid;
    margin-bottom: 6px;
    text-align: left;
  }

  .right-side {
    padding: 1em;

    form {
      padding-right: 0px;
    }

    textarea {
      font-size: 15px;
      line-height: 1.3;
      padding: 0;
    }

    .title {
      margin-bottom: 10px !important;
    }

    .details-info .body {
      max-height: 400px !important;
      overflow-y: auto !important;
    }
  }

  .formTitle {
    border-bottom: 1px $af-gray solid;
    padding-bottom: 15px;

    .label-container-main {
      margin: 0;

      .label-container-title {
        margin-bottom: 8m;
      }

      .label-container-value {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 20px;
        margin: 7px 0;
      }
    }

    .title-container {
      display: none !important;
    }
  }

  .title-container {
    background-color: transparent !important;
    border: 0 !important;
  }

  .save-button button {
    background: var(--mainColor) !important;
    border: var(--mainColor) 1px solid !important;
    color: white !important;
  }

  .cancel-button button {
    background: white !important;
    color: var(--mainColor) !important;
  }

  .cancel-button {
    order: 1;
  }

  .save-button {
    order: 2;
  }

  .cancel-button,
  .save-button {
    display: flex;
    flex-grow: 0;
    flex-basis: 0;
    width: 0;

    button {
      width: fit-content;
      height: em(31);
      font-family: 'Open Sans' !important;
      padding: 10px 15px !important;
      font-size: 14px !important;
      box-shadow: none;
      letter-spacing: 0.4px;
    }

    button:disabled {
      // your css rules
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.26) !important;
      border: none !important;

      .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: rgba(0, 0, 0, 0.26) !important;
      }
    }

    .form-field-group-field {
      text-align: right;
    }
  }

  .action-detail {
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-top: 14px;
      margin-bottom: 5px;
    }

    .subtitle1,
    .subtitle2 {
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 166%;
      /* 19.92px */
      letter-spacing: 0.4px;
      color: var(--text-secondary, $af-subtitle);

      b {
        color: var(--text-secondary, $af-subtitle);
        font-weight: 700 !important;
      }
    }

    .subtitle2 {
      text-align: right;
    }

    .body {
      font-size: 16px;
      margin-top: 14px;
      line-height: 1.5;
    }
  }

  .cancel-button {
    padding-right: 0 !important;
    text-align: right !important;
  }

  .divider-label {
    mat-label {
      font-size: 16px;
      color: #00000099;
    }
  }

  .titleLabel {
    mat-label {
      font-size: 16px !important;
      font-family: 'Open Sans';
      font-weight: 600;
      margin-bottom: 1em;
    }
  }

  .mdc-checkbox__checkmark-path {
    stroke-width: 1px !important;
  }

  .action-information-list {
    list-style: none !important;
    padding: 0;
    height: 350px;
    margin: 0;

    li {
      position: relative;
      margin-bottom: 0;
      padding-bottom: 6px;
      padding-left: em(22);
      margin-top: 1em;
      text-align: left;
      cursor: pointer;

      p,
      b {
        font-size: 13px;
      }

      b {
        color: #00000099;
      }

      p {
        font-weight: bold;
      }

      p:first-letter {
        text-transform: uppercase;
      }

      span {
        font-size: 11px;
        color: gray;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.057);
      }

      &::after {
        content: '•';
        position: absolute;
        left: 2px;
        color: rgb(198 198 198);
        font-size: 37px;
        top: 0px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 8px;
        border-left: 1px solid $af-gray;
        height: 70%;
        width: 1px;
        top: 17px;
      }

      &:last-child::before {
        /* last li's line */
        height: 0px;
        /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
      }

      &.selected {
        &::after {
          color: #2e7d32;
        }
      }
    }
  }

  .groups {
    flex-direction: unset !important;
  }

  .left-side {
    border-right: 1px $af-gray solid;

    &.note-sidebar {
      .title-container {
        border-bottom: 1px $af-gray solid;

        .title {
          border-bottom: 1px $af-gray solid;
        }
      }
    }

    .title {
      margin: 0 !important;
    }

    &.mission-details {
      .title-container {
        padding: 0;
        margin: 0;

        .title {
          padding: 5px 0 0 12px !important;
        }
      }

      form {
        padding-bottom: 1em;
      }

      .form-field-group {
        margin: 12px 0;

        .label-container-main {
          margin: 0;
          font-weight: 400;

          .label-container-title {
            margin-bottom: 8px;
            color: #00000099;
            font-size: 13px;
          }

          .label-container-value {
            font-size: 15px;
            line-height: 1.3;
          }
        }
      }
    }

    .title-container {
      background: transparent !important;
      border: none;
      height: unset;

      .title {
        margin: 0;
        width: 100%;
        padding: 0.7em 0.5em 0.7em 0;
      }
    }
  }

  .left-side,
  .right-side {
    .title {
      font-size: 1.2em !important;
    }
  }

  .filter-by-title {
    padding: 1em 0;
    margin: 0;
  }

  .list-select {
    border: unset !important;
    border-radius: unset !important;
    height: unset !important;
    line-height: unset !important;
    font-size: unset !important;
  }

  .text-area {
    background: unset !important;
  }
}

.label-container-value {
  line-height: 1.3 !important;
}
