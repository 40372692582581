// Styling for a simple mat-menu
.common-menu {
  background: white;
  margin-top: 10px;

  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 150px !important;
  }

  &--body {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;
      transition: ease-in background 0.2s;

      &:hover {
        background: var(--mat-expansion-header-hover-state-layer-color);
      }
    }
  }
}
